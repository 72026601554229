.TirdPage_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 80vh;
  padding: 1rem;
  background: #1a1a1a;
  flex-direction: column;
  z-index: 1;
}

.thirdpage_title_my-projects {
  position: relative;
  font-size: 1.8rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 1.5rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

/* Card container with 3D perspective */
.tird_page-card {
  position: relative;
  width: 60%;
  max-width: 600px;
  height: 350px;
  perspective: 2000px;
  cursor: pointer;
  margin-bottom: 1rem;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease;
}

.tird_page-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.3);
}

/* Front and back card faces */
.tird_page-card .card-front,
.tird_page-card .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hides the back when not visible */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  transition: transform 0.6s ease-in-out;
}

.card-front-frame {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 1.2rem;
  transition: all 0.4s ease;
  overflow: hidden;
}

.card-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(26, 26, 26, 0.85);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 1.2rem;
  z-index: 2;
}

.card-front-frame::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  filter: blur(20px);
  opacity: 0.3;
  z-index: 1;
  transition: all 0.4s ease;
}

.card-front-frame:hover::after {
  opacity: 0.4;
  filter: blur(15px);
}

.card-image {
  position: relative;
  width: 60%;
  height: auto;
  max-height: 160px;
  object-fit: contain;
  border-radius: 8px;
  margin-bottom: 0.8rem;
  transition: transform 0.4s ease;
  filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.2));
  z-index: 3;
}

.card-front-frame:hover .card-image {
  transform: scale(1.03);
}

.thirdpage_front_card-label {
  font-size: 1.3rem;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  margin-top: 0.5rem;
  letter-spacing: 1px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.card-back {
  background: #2d3436;
  padding: 1.2rem;
  border-radius: 12px;
  color: #ffffff;
}

.card-back h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 0.8rem;
  text-align: center;
}

.flip_description {
  background: #343a40;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin: 0.6rem 0;
  max-height: 200px;
  overflow-y: auto;
}

.flip_description::-webkit-scrollbar {
  width: 6px;
}

.flip_description::-webkit-scrollbar-track {
  background: #2d3436;
  border-radius: 3px;
}

.flip_description::-webkit-scrollbar-thumb {
  background: #74b9ff;
  border-radius: 3px;
}

.flip_description h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #74b9ff;
  margin-bottom: 0.3rem;
}

.flip_description p {
  color: #f5f6fa;
  line-height: 1.4;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
}

.card-back a {
  display: inline-block;
  margin-top: 0.8rem;
  padding: 0.5rem 1.2rem;
  background: linear-gradient(135deg, #0984e3 0%, #6c5ce7 100%);
  color: #ffffff;
  border-radius: 20px;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  font-size: 0.9rem;
}

.card-back a:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  background: linear-gradient(135deg, #0984e3 20%, #6c5ce7 80%);
}

/* Flip card */
.tird_page-card.flipped .card-front {
  transform: rotateY(180deg); /* Hide the front */
}

.tird_page-card.flipped .card-back {
  transform: rotateY(360deg); /* Show the back */
}

/* Navigation arrows */
.arrow_third_page {
  position: absolute;
  font-size: 1.2rem;
  color: #ffffff;
  cursor: pointer;
  z-index: 10;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
  border-radius: 50%;
  transition: all 0.3s ease;
  top: 50%;
  transform: translateY(-50%);
}

.arrow_third_page:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-50%) scale(1.1);
}

.arrow_third_page-left {
  left: 15%;
}

.arrow_third_page-right {
  right: 15%;
}

/* Dot navigation */
.dots-container {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin: 0.8rem 0;
  padding-bottom: 0.5rem;
}

.dot {
  height: 3px;
  width: 20px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot:hover {
  background: rgba(255, 255, 255, 0.3);
}

.dot.active {
  background: #74b9ff;
  width: 30px;
}

@media screen and (max-width: 768px) {
  .TirdPage_container {
    padding: 0.5rem;
    min-height: 75vh;
  }

  .thirdpage_title_my-projects {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .tird_page-card {
    width: 85%;
    height: 320px;
  }

  .card-image {
    width: 70%;
    max-height: 140px;
  }

  .thirdpage_front_card-label {
    font-size: 1.1rem;
  }

  .card-back h3 {
    font-size: 1.2rem;
  }

  .flip_description {
    padding: 0.7rem;
    max-height: 180px;
  }

  .flip_description h4 {
    font-size: 0.85rem;
  }

  .flip_description p {
    font-size: 0.75rem;
  }

  .card-back a {
    font-size: 0.8rem;
    padding: 0.4rem 1rem;
  }

  .arrow_third_page {
    font-size: 1rem;
    width: 30px;
    height: 30px;
  }

  .arrow_third_page-left {
    left: 5%;
  }

  .arrow_third_page-right {
    right: 5%;
  }

  .dot {
    width: 15px;
  }

  .dot.active {
    width: 25px;
  }

  .card-front-frame::before {
    filter: blur(6px);
  }
}
