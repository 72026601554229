

/* Welcome Text */
.hi {
  font-size: 1.8rem;
  font-weight: 800;
}

/* Section Styles */
section {
  scroll-snap-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Who Am I Section */

/* Section Headings and Text */
section h2 {
  opacity: 0;
  color: white;
}

section span {
  color: white;
  font-size: 1rem;
}

/* Page Styling */
section.is-visible h2 {
  transition: 2023ms;
  transform: translateY(-150px);
  opacity: 1;
}

/* Pages Container */
.pages {
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  position: relative;
  overflow: hidden;
}

/* Individual Page Styling */
.page {
  height: 100vh;
  width: 100vw;
  position: absolute;
  bottom: -100vh;
  transition: bottom 0.7s;
  display: flex;
}

.page:first-child {
  bottom: 0;
}

/* Page Background Colors */
.first {
  background-color: #000000;
}

.second {
  background-color: #000000;
}

.third {
  background-color: #000000;
}

.fourth {
  background-color: #000000;
}


/* dotdot Styles */
.dotdot {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.dotdot > li {
  list-style: none;
  height: 30px;
  width: 2px;
  border-radius: 12%;
  background: #cece15;
  margin-top: 10px;
  transition: 0.7s;
  border: 0;
  padding: 0;
  margin-top: 10px;
  cursor: pointer;
}

.dotdot > li.active {
  position: relative;
  background: hsl(0, 100%, 24%);
  transform: scale(150%);
  cursor: pointer;
  box-shadow: 0 0 0 0.4px white;
  height: 10px;

}


/* Image Grid Styles */
.image-grid {
  display: grid;
  grid-template-columns: repeat(6, 100%);
  transition: transform 0.5s ease;
}


